import { template as template_ab99c25a766a4aa1bbe6be8654756aec } from "@ember/template-compiler";
const FKControlMenuContainer = template_ab99c25a766a4aa1bbe6be8654756aec(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
