import { template as template_db70b3cbabe445ee8b2acde7aeba5c88 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_db70b3cbabe445ee8b2acde7aeba5c88(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
