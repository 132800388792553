import { template as template_5c46804ee35341489ee67f82b6230823 } from "@ember/template-compiler";
const SidebarSectionMessage = template_5c46804ee35341489ee67f82b6230823(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
